/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Image from '~components/image'
import resolveLink from '~utils/resolveLink'
import { typeColors, breakpoints } from '~styles/global'
import { DateTime } from 'luxon'
import { css } from "@emotion/react"
import RichText from "~components/richText"

const { mobile, tablet } = breakpoints

const formatDate = (date, enddate) => {
  if(!enddate || DateTime.fromISO(date).toFormat('dMMyy') === DateTime.fromISO(enddate).toFormat('dMMyy')) return DateTime.fromISO(date).toFormat('dd.MM.yyyy')
  return DateTime.fromISO(date).toFormat('dd.MM.yy') + '—' + DateTime.fromISO(enddate).toFormat('dd.MM.yy')
}

const formatDateWords = (date, enddate) => {
  if(!enddate || DateTime.fromISO(date).toFormat('dMMyy') === DateTime.fromISO(enddate).toFormat('dMMyy')) return DateTime.fromISO(date).toFormat('d MMMM')
  return DateTime.fromISO(date).toFormat('d MMMM') + '—' + DateTime.fromISO(enddate).toFormat('D MMMM')
}

const Card = ({ doc }) => {
  const c = doc._rawContent ?? doc.content
  const cardRef = useRef()
  const [isFirstInRow, setIsFirstInRow] = useState(false)
  const [windowWidth, setWindowWidth] = useState()

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
    const mediaQuery = window.matchMedia('(min-width: 1025px)')
    if (mediaQuery.matches) {
      setIsFirstInRow(cardRef?.current?.offsetLeft < 250)
    } else {
      setIsFirstInRow(cardRef?.current?.offsetLeft < 100)
    }
  }

  const [videoPlaying, setVideoPlaying] = useState(false)

  const [videoLoaded, setVideoLoaded] = useState(false)

  const videoRef = useRef()

  useEffect(() => {
    if (videoRef.current && videoPlaying) {
      videoRef.current.play()
    } else if (videoRef.current && !videoPlaying) {
      videoRef.current.pause();
    }
  }, [videoPlaying]);



  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if(!c) return null


  return(
    <Link to={resolveLink(doc)} ref={cardRef} css={css`
        position: relative;
        padding: 1rem;
        box-sizing: border-box;
        grid-column: span 1;
        display: block;
        ${doc._type === 'program' && c.elements &&'grid-row: span 2;'}
        &:nth-of-type(1){
          padding-top: 0.5rem;
          &:before{
            display: block;
          }
        }
        &:nth-of-type(1){
          padding-top: 0.5rem;
          &:before{
            display: none;
          }
        } 
        &:before{
          position: absolute;
          content: "";
          display: block;
          background: var(--white);
          top: 0px;
          left: 0.5rem;
          right: 0.5rem;
          height: 2px;
        }
        &:after{
          position: absolute;
          content: "";
          display: block;
          background: var(--white);
          top: 0;
          bottom: 0;
          left: ${isFirstInRow ? '-2px' : '-1px'};
          width: 2px;
          ${tablet}{
            display: ${isFirstInRow ? 'none' : 'block'};
          }
          ${mobile}{
            display: none;
          }
        }
      `}>
      <div css={css`
          min-height: 90px;
          display: flex;
          flex-direction: column;
          h4{
            text-align: center;
          }
        `}>
        <div css={css`min-height:16.25px;`}>
          {doc._type === "work" && <h4>{c.meta?.year}</h4>}
          {["event", "program" ].includes(doc._type) && <h4>{formatDate(c.startdate, c.enddate)}</h4>}
          {["article"].includes(doc._type) && <h4>{formatDate(c.date)}</h4>}
        </div>
        <h3 className="p" css={css`
          text-shadow: 0px 4px 4px ${typeColors[doc._type]};
          margin: 0.75rem 0 1rem;
          `}>
          <span css={css`font-style: italic;`}>{doc.title}{doc._type === "work" && ','}</span>
          {doc._type === "work" && (
            <>
            <br/>
            <span>{c.artists?.map(d => d.title).join(', ')}</span>
            </>
          )}
        </h3>
      </div>
      { c?.thumb?.thumb?.asset?.url && windowWidth > 1024 ?
      <div role="img" onMouseEnter={() => setVideoPlaying(true)} onMouseLeave={() => setVideoPlaying(false)} onCanPlay={() => setVideoLoaded(true)} css={css`
        padding-bottom: 62.75%;
        position: relative;
      `}>
        <div css={css`
          background-image: url('/static.png');
          image-rendering: pixelated;
          background-repeat: repeat;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          transition: opacity 0.3s 0.3s;
          opacity: ${videoLoaded ? '0' : '1'};
          background-size: 350px;`
        }></div>
        <video width="100%" ref={videoRef} muted="muted" css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        `}>
          <source src={c.thumb?.thumb?.asset?.url} type="video/mp4"/>

        </video>
      </div>
      : <Image id={c.thumb?.thumbImage?.asset?.id ?? c.thumb?.thumbImage?.asset?._ref} />
      }
      <h4 css={css`margin: 0.8rem 0 1.2rem;`}>{doc._type === 'textDoc' ? 'text' : doc._type}</h4>
      <div className="h3">
        { c.customCardText ?       
        <div className="h3">
          <RichText content={c.customCardText} />
        </div>
        : 
        <p css={css`
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        `}>
          {doc._type !== 'program' && c.text?.[0].children.map(c => c.text).join(' ')}
        </p>
        }
      </div>
      {doc._type === 'program' && c.elements &&
        <div>
          {c.customCardText ? 
            null
            :
            c.elements.map(el => (
              <div key={el.slug?.current + 'related'}>
                <h5>{formatDateWords(el.content?.date)}</h5>
                <p css={css`font-style: italic;`}>{el.title}</p>
              </div>
            ))
          }
        </div>
      }
    </Link>
  )
}

export default Card
